import { createTheme, lightThemePrimitives } from 'baseui';
import { PROJECT } from '../constant';

const initialTheme = {
  primary: '#00C58D',
  primary700: '#00C58D',
  primaryRGBA5: 'rgba(0,197,141,0.5)',
};

const themeReducer = (projectName) => {
  switch (projectName) {
    case PROJECT.chugoku:
      return {
        ...initialTheme,
        primary: '#ED6E33',
        primary700: '#ED6E33',
        primaryRGBA5: 'rgba(237,110,51,0.5)',
      };
    case PROJECT.tokyo:
      return {
        ...initialTheme,
        primary: '#42adff',
        primary700: '#42adff',
        primaryRGBA5: 'rgba(66, 173, 255,0.5)',
      };
    case PROJECT.mirai:
      return {
        ...initialTheme,
        primary: '#42adff',
        primary700: '#42adff',
        primaryRGBA5: 'rgba(66, 173, 255,0.5)',
      };
        case PROJECT.tohoku:
      return {
        ...initialTheme,
        primary: '#a11b5c',
        primary700: '#a11b5c',
        primaryRGBA5: 'rgba(161, 27, 92,0.5)',
      };
    default:
      return {
        ...initialTheme,
      };
  }
};

const projectTheme = themeReducer(process.env.REACT_APP_PROJECT);

export const theme = createTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: "'Lato', sans-serif",
    primary: projectTheme.primary,
    primary700: projectTheme.primary700,
  },
  {
    name: 'custom-typography',
    typography: {
      primaryFontFamily: "'Lato', sans-serif",
      font11: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold11: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '11px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font12: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold12: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font13: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold13: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '13px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font14: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold14: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font16: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold16: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font18: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold18: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font21: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '21px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold21: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '21px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font24: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold24: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font30: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '30px',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontBold30: {
        fontFamily: "'Lato', sans-serif",
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: 1.5,
      },
      font350: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '24px',
      },
      font450: {
        fontSize: '15px',
        fontWeight: 700,
        lineHeight: '22px',
      },
    },
    sizing: {
      scale25: '25px',
    },
    borders: {
      borderE6: {
        borderColor: '#E6E6E6',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      borderEA: {
        borderColor: '#eaeaea',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
    colors: {
      // primary: "#00C58D",
      // primary700: "#00bd87",
      // primary400: "#03D3B5",
      primary: projectTheme.primary,
      primaryRGBA5: projectTheme.primaryRGBA5,
      primary700: '#42adff',
      primary400: '#42adff',
      red400: '#FC5C63',
      red700: '#FC6687',
      greyE6: '#E6E6E6',
      textDark: '#161F6A',
      textNormal: '#666D92',
      borderE6: '#E6E6E6',
      borderF1: '#f1f1f1',
      // backgroundF7: "#f7f7f7",
      backgroundF7: '#f1f1f1',
      lightGreen: '#00d4b5',
      warning: '#f4c243',
      blue400: '#2067fa',
      purple400: '#8A6AFC',
      grayF8: '#f8f8f8',
      grayF16: '#5b5f66',
      white: '#ffffff',
    },
    buttonBorderRadius: '3px',
  }
);
