import React, { lazy, Suspense, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { InLineLoader } from './components/InlineLoader/InlineLoader'
import { PROJECT } from './constant'
import { sidebarMenus } from './containers/Layout/Sidebar/Sidebar'
import PointExchange from './containers/PointExchange/PointExchange'
import VisitorSetting from './containers/VisitorSetting/VisitorSetting'
import AuthProvider, { AuthContext } from './context/auth'
import {
  AUTHORIZER,
  CAMPAIGN,
  CATEGORY,
  CUSTOMERS,
  DASHBOARD,
  FLYER,
  GIFTS,
  HANBAITEN_MAIL,
  INTRO_PRODUCT,
  LOGIN,
  MAKER,
  MAKER_LINK,
  ORDERS,
  POINT,
  POINT_ACTION,
  POINT_EXCHANGE,
  PRICE_SETTINGS,
  PRODUCTS,
  SALE,
  SEND_MAIL_SETTING,
  TAG_SETTINGS,
  VISITOR_SETTING,
  VR_LINK,
  COUPON,
  TOPICS
} from './settings/constants'

const MailSetting = lazy(() => import('./containers/MailSetting'))
const Products = lazy(() => import('./containers/Products/Products'))
const ProductsMirai = lazy(() => import('./containers/ProductsMirai/ProductsMirai'))
const Gifts = lazy(() => import('./containers/Gifts/Gifts'))
const AdminLayout = lazy(() => import('./containers/Layout/Layout'))
const Category = lazy(() => import('./containers/Category/Category'))
const CategoryMirai = lazy(() => import('./containers/CategoryMirai/CategoryMirai'))
const Maker = lazy(() => import('./containers/Maker/Maker'))
const HanbaitenMail = lazy(
  () => import('./containers/HanbaitenMail/HanbaitenMail')
)
const Orders = lazy(() => import('./containers/Orders/Orders'))
const OrdersTokyo = lazy(() => import('./containers/Orders/OrdersTokyo'))
const Point = lazy(() => import('./containers/Point/Point'))
const PointAction = lazy(() => import('./containers/PointAction/PointAction'))
const DashBoard = lazy(() => import('./containers/Dashboard/Dashboard'))
const PriceSettings = lazy(
  () => import('./containers/PriceSettings/PriceSettings')
)
// const Settings = lazy(() => import('./containers/Settings/Settings'))
// const SiteSettingForm = lazy(
//   () => import('./containers/SiteSettingForm/SiteSettingForm')
// )
// const StaffMembers = lazy(
//   () => import('./containers/StaffMembers/StaffMembers')
// )
const Customers = lazy(() => import('./containers/Customers/Customers'))
const CustomersTokyo = lazy(
  () => import('./containers/Customers_Tokyo/Customers')
)
// const Coupons = lazy(() => import('./containers/Coupons/Coupons'))
const Campaign = lazy(() => import('./containers/Campaign/Campaign'))
const Login = lazy(() => import('./containers/Login/Login'))
const NotFound = lazy(() => import('./containers/NotFound/NotFound'))
const TagSetting = lazy(() => import('./containers/TagSetting/TagSetting'))
const Sale = lazy(() => import('./containers/Sale/Sale'))
const IntroProduct = lazy(
  () => import('./containers/IntroduceProduct/Introduce')
)
const VRLink = lazy(() => import('./containers/VRLink/VRLink'))
const MakerLink = lazy(() => import('./containers/MakerLink/MakerLink'))
const Flyer = lazy(() => import('./containers/Flyer/Flyer'))
const Authorizer = lazy(() => import('./containers/Authorizer'))
const Coupon = lazy(() => import('./containers/Coupon/coupon'))
const Topics = lazy(() => import('./containers/Topics/Topics'))
/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

const PrivateRoute = React.memo(
  ({ path, children, ...rest }: any) => {
    const { isAuthenticated, role } = useContext(AuthContext)

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            path !== '/' ? (
              sidebarMenus.filter((item) => item && item.path === path)[0] &&
                sidebarMenus
                  .filter((item) => item && item.path === path)[0]
                  .roleAccess.includes(role) ? (
                  children
                ) : (
                  <NotFound />
                )
            ) : (
                children
              )
          ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            )
        }
      />
    )
  },
)

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DashBoard />
                {/* <Dashboard /> */}
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                {process.env.REACT_APP_PROJECT === PROJECT.tokyo ? (
                  <ProductsMirai />
                ) : (
                    <Products />
                  )}
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SEND_MAIL_SETTING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MailSetting />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={VISITOR_SETTING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <VisitorSetting />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={GIFTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Gifts />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRICE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PriceSettings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                {process.env.REACT_APP_PROJECT === PROJECT.tokyo ? (
                  <CategoryMirai />
                ) : (
                    <Category />
                  )}
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MAKER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Maker />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={HANBAITEN_MAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <HanbaitenMail />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <div style={{ width: `calc(100%-200px)!important` }}>
                  {process.env.REACT_APP_PROJECT === PROJECT.tokyo
                  || process.env.REACT_APP_PROJECT === PROJECT.mirai
                    ? (
                    <OrdersTokyo />
                  ) : (
                      <Orders />
                    )}
                </div>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={POINT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Point />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={POINT_ACTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PointAction />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={POINT_EXCHANGE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PointExchange />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                {process.env.REACT_APP_PROJECT === PROJECT.tokyo
                || process.env.REACT_APP_PROJECT === PROJECT.mirai
                  ? (
                  <CustomersTokyo />
                ) : (
                    <Customers />
                  )}
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CAMPAIGN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Campaign />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={TAG_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <TagSetting />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={VR_LINK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <VRLink />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MAKER_LINK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MakerLink />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SALE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Sale />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INTRO_PRODUCT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <IntroProduct />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={FLYER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Flyer />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COUPON}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupon />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={TOPICS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Topics />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          {/* <PrivateRoute path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute> */}
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={AUTHORIZER}>
            <Authorizer />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  )
}

export default React.memo(Routes, () => false)
